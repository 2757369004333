<template>
  <span class="inline-flex items-baseline">
    <i v-if="iconClass" :class="iconClass" class="text-sm" />
    <component v-else :is="icon" class="h-5 w-5" aria-hidden="true" />
    <slot />
  </span>
</template>

<script setup>
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  DevicePhoneMobileIcon,
  BriefcaseIcon,
  BookOpenIcon,
  XCircleIcon,
  NewspaperIcon,
  MapPinIcon,
  StarIcon,
  CurrencyDollarIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  XMarkIcon,
  LockClosedIcon,
  LockOpenIcon,
  UserGroupIcon,
  UserIcon,
  Bars3Icon,
  BuildingOffice2Icon,
  GlobeAmericasIcon,
  Square3Stack3DIcon,
  RectangleStackIcon,
  CursorArrowRaysIcon,
  PlusCircleIcon,
  SquaresPlusIcon,
  PencilSquareIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  CalendarIcon,
  FunnelIcon,
  ViewColumnsIcon,
  CalculatorIcon,
  ChevronDownIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleDownIcon,
  QrCodeIcon,
  CubeIcon,
  SparklesIcon,
  EyeIcon,
  EyeSlashIcon,
  BarsArrowDownIcon,
  IdentificationIcon,
  BoltIcon,
  PhoneIcon,
  FolderIcon,
  PhotoIcon,
  ArrowsPointingOutIcon,
  ClockIcon,
  FlagIcon,
  GiftIcon,
  ComputerDesktopIcon,
  EllipsisHorizontalCircleIcon,
  PencilIcon,
  ArrowsRightLeftIcon,
  WrenchIcon,
  UserPlusIcon,
  ArrowRightIcon,
  ChartPieIcon,
  ArrowPathIcon,
  BanknotesIcon,
  ShareIcon,
  KeyIcon,
  EllipsisVerticalIcon,
} from "@heroicons/vue/24/outline";
import { computed } from "vue";

const props = defineProps(["iconName", "iconClass"]);

const icon = computed(() => {
  switch (props.iconName) {
    case "mobile":
      return DevicePhoneMobileIcon;
    case "desktop":
      return ComputerDesktopIcon;
    case "bars":
      return Bars3Icon;
    case "verticalDots":
      return EllipsisVerticalIcon;
    case "share":
      return ShareIcon;
    case "idCard":
      return IdentificationIcon;
    case "phone":
      return PhoneIcon;
    case "flag":
      return FlagIcon;
    case "gift":
      return GiftIcon;
    case "book":
      return BookOpenIcon;
    case "capitalStack":
      return BarsArrowDownIcon;
    case "public":
      return EyeIcon;
    case "private":
      return EyeSlashIcon;
    case "layout":
      return QrCodeIcon;
    case "cube":
      return CubeIcon;
    case "sparkles":
      return SparklesIcon;
    case "calculator":
      return CalculatorIcon;
    case "calendar":
      return CalendarIcon;
    case "clock":
      return ClockIcon;
    case "cursor":
      return CursorArrowRaysIcon;
    case "plusCircle":
      return PlusCircleIcon;
    case "squaresPlus":
      return SquaresPlusIcon;
    case "caution":
      return ExclamationTriangleIcon;
    case "check":
      return CheckCircleIcon;
    case "warning":
      return XCircleIcon;
    case "star":
      return StarIcon;
    case "dollar":
      return CurrencyDollarIcon;
    case "shieldCheck":
      return ShieldCheckIcon;
    case "shieldExclamation":
      return ShieldExclamationIcon;
    case "xMark":
      return XMarkIcon;
    case "skip":
      return ChevronDoubleRightIcon;
    case "lock":
      return LockClosedIcon;
    case "unlock":
      return LockOpenIcon;
    case "user":
      return UserIcon;
    case "userGroup":
      return UserGroupIcon;
    case "userPlus":
      return UserPlusIcon;
    case "briefcase":
      return BriefcaseIcon;
    case "news":
      return NewspaperIcon;
    case "marker":
      return MapPinIcon;
    case "globe":
      return GlobeAmericasIcon;
    case "buildings":
      return BuildingOffice2Icon;
    case "questionMark":
      return QuestionMarkCircleIcon;
    case "layers":
      return Square3Stack3DIcon;
    case "rectangleStack":
      return RectangleStackIcon;
    case "pencil":
      return PencilIcon;
    case "pencilSquare":
      return PencilSquareIcon;
    case "wrench":
      return WrenchIcon;
    case "documentCheck":
      return DocumentCheckIcon;
    case "documentText":
      return DocumentTextIcon;
    case "filter":
      return FunnelIcon;
    case "layoutSelector":
      return ViewColumnsIcon;
    case "verticalOpening":
      return ChevronDoubleDownIcon;
    case "folder":
      return FolderIcon;
    case "photo":
      return PhotoIcon;
    case "partialInterest":
      return ChartPieIcon;
    case "arrowsOut":
      return ArrowsPointingOutIcon;
    case "arrowsLeftRight":
      return ArrowsRightLeftIcon;
    case "rightArrow":
      return ArrowRightIcon;
    case "circularArrows":
      return ArrowPathIcon;
    case "cash":
      return BanknotesIcon;
    case "key":
      return KeyIcon;
    case "horizontalDotsCircle":
      return EllipsisHorizontalCircleIcon;
    case "expandCollapse":
      return ChevronDownIcon;
    case "easyDataInput":
      return BoltIcon;
    default:
      return InformationCircleIcon;
  }
});
</script>
