import InlineAppIcon from "@/components/help-center/custom-tags/InlineAppIcon.vue";

export const inlineAppIcon = {
  render: InlineAppIcon,
  attributes: {
    iconName: {
      type: String,
    },
    iconClass: {
      type: String,
    },
  },
};
